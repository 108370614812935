import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../pages/Home";
import { publicPath } from "~/vue.config";

Vue.use(VueRouter);

export default new VueRouter({
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
  ],
  mode: "history",
  base: publicPath,
});
