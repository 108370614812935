export function makeCanvasFullScreen(canvas) {
  var b = document.body;
  var d = document.documentElement;
  let fullw = Math.max(
    b.clientWidth,
    b.scrollWidth,
    d.scrollWidth,
    d.clientWidth
  );
  let fullh = Math.max(
    b.clientHeight,
    b.scrollHeight,
    d.scrollHeight,
    d.clientHeight
  );
  canvas.width = fullw;
  canvas.height = fullh;
}
