<template>
  <div class="app">
    <div class="home d-flex align-items-center flex-column">
      <div class="title font-spell">
        <span class="he" @mousedown="pause">T</span>he
        <span class="she" @mousedown="play">S</span>pell
      </div>

      <div class="ps">coming soon...</div>
    </div>

    <div id="effects">
      <StarSky2 />
    </div>
  </div>
</template>

<script>
import StarSky2 from "@/effects/StarSky2.vue";

export default {
  components: {
    StarSky2,
  },

  data: () => ({
    intro: null,
    lastBlast: null,
    redirecting: false,
    delayedIntro: null,
  }),

  mounted() {
    this.intro = new Audio(`${this.$router.options.base}music/intro.mp3`);
    this.intro.volume = 0.7;
  },

  beforeDestroy() {
    this.intro.pause();
  },

  methods: {
    onfocus() {
      // Start intro
      if (this.intro.paused) {
        this.intro.play();
      }
    },

    play() {
      this.intro.play();
    },

    pause() {
      this.intro.pause();
    },
  },
};
</script>

<style scoped lang="scss">
.app {
  height: 100%;
}

.home {
  height: 100%;
  overflow: hidden;

  .title {
    padding-top: 15vh;
    font-size: 9vw;
    z-index: 1;
    flex: 25% 0 0;
    width: 100%;
    cursor: default;
  }

  .he,
  .she {
    user-select: none;
  }

  .ps {
    z-index: 1;
    font-size: 4vw;
    font-family: Charm;
    color: transparent;
  }

  @media screen and (min-aspect-ratio: 1/1) {
    .title {
      font-size: 7vw;
      padding-top: 15vh;
    }
  }
}
</style>
