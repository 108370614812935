//const path = require("path");

module.exports = {
  publicPath: "/",
  devServer: {
    //host: "192.168.0.11",
    // host: "127.0.0.1",
    // public: "91.222.131.219", //LOCAL comment
    // public:
    //   process.env.NODE_ENV === "production"
    //     ? "https://kirsh.dev"
    //     : "http://192.168.0.11:8081",
    //port: "8081",
    disableHostCheck: true,
  },
  configureWebpack: {
    resolve: {
      alias: {
        "~": __dirname,
      },
    },
  },
  pluginOptions: {
    compression: {
      gzip: {
        filename: "[path].gz[query]",
        algorithm: "gzip",
        include: /\.(js|css|html|svg|json)(\?.*)?$/i,
        minRatio: 0.8,
      },
    },
  },
  chainWebpack: config => {
    config.performance.maxEntrypointSize(3000 * 1024).maxAssetSize(2000 * 1024);
    config.plugins.delete("prefetch");
  },
  transpileDependencies: ["vuetify"],
};
