<template>
  <div id="app">
    <router-view ref="view" />

    <loader v-if="showLoader"></loader>
  </div>
</template>

<script>
import loader from "@/components/Loader";

export default {
  components: { loader },
  data: () => ({
    showLoader: true,
  }),

  methods: {
    loading() {
      this.showLoader = true;
      setTimeout(() => {
        this.showLoader = false;
      }, 2000);
    },
  },

  mounted() {
    // Screen size
    window.addEventListener("resize", () => {
      this.$store.commit("checkScreen");
    });
    this.$store.commit("checkScreen");

    // Show loading
    this.$router.afterEach(this.loading);
    this.loading();
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  height: 100%;
}
</style>
