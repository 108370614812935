<template>
  <div class="loader">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8500 9200"
      width="15%"
      class="heart"
    >
      <g>
        <path
          class="fil1 str0 test"
          fill="white"
          d="M6078.97 2013.92c-713.07,0 -1438.98,337.26 -1853.31,1048.01 -415.92,-714.05 -1140,-1051.31 -1851.11,-1051.31 -1030.27,0 -2032.89,707.8 -2032.89,2003.78 0,1508.58 1803.15,3051.91 3884,5116.85 2081.21,-2064.94 3884,-3608.26 3884,-5116.85 0,-1298.17 -1001.76,-2000.59 -2030.69,-2000.59l0 0.12z"
        />

        <path
          class="fil2 str1"
          fill="none"
          stroke="black"
          stroke-width="180"
          d="M693.26,5522.13 1913.22,5522.13 2409.86,4166.61 2937.54,6308.35 3408.3,5077.11 3625.58,5506.49 7800.08,5506.49 "
        />
      </g>
    </svg>
    <div class="text font-spell"><label>loading...</label></div>
  </div>
</template>

<script>
export default {
  mounted() {
    setTimeout(() => {
      const audio = new Audio(
        `${this.$router.options.base}music/heartbeat.mp3`
      );
      audio.play();
    }, 320);
  },
};
</script>

<style scoped>
.loader {
  position: fixed;
  z-index: 9000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: black;
}

.heart {
  margin-top: 15vh;
}

.fil1 {
  transform: scale(0.9);
  transform-origin: 50% 50%;

  animation: beat 2s linear forwards;
  animation-delay: 0.2s;
}

@keyframes beat {
  18% {
    transform: scale(0.9);
  }
  21% {
    transform: scale(1);
  }
  27% {
    transform: scale(0.9);
  }

  33% {
    transform: scale(0.9);
  }
  36% {
    transform: scale(1);
  }
  44% {
    transform: scale(0.9);
  }
}

.fil2 {
  stroke-dasharray: 13000;
  stroke-dashoffset: 13000;

  animation: pulse 2s linear forwards;
  animation-delay: 0.2s;
}

@keyframes pulse {
  18% {
    stroke-dashoffset: 11800;
  }
  29% {
    stroke-dashoffset: 7900;
  }
  40% {
    stroke-dashoffset: 6000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.text {
  font-size: calc(10px + 3vw);
}
</style>
