import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import bootstrap from "./plugins/bootstrap";
import fontawesome from "./plugins/fontawesome";

import Vuebar from "vuebar";
Vue.use(Vuebar);

// Add custom styles
import "@/assets/css/styles.css";

Vue.config.devtools = false;

/* eslint-disable no-new */
new Vue({
  store,
  router,
  bootstrap,
  fontawesome,

  beforeCreate() {
    this.$store.commit("initializeStore");
  },

  render: h => h(App),
}).$mount("#app");
