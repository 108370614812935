import Vue from "vue";
import Vuex from "vuex";

import { version } from "~/package.json";

Vue.use(Vuex);

const storeStorage = localStorage;
const debug = process.env.NODE_ENV !== "production";

// Init track resources
var uncoveredReady = [],
  uncoveredSeen = [];

const store = new Vuex.Store({
  state: {
    /* User parameters */
    version: version,
    login: false,
    curtrack: 0, // Currently listening track
    maxtrack: 0, // Max progress of listening
    lyricsFormat: "en-ru",
    progress: 0,
    uncoveredReady, // Opened tracks, covers was not seen
    uncoveredSeen, // Opened tracks, covers was seen

    /* System parameters */
    playing: false,
    keepon: false,
    showPlaylist: false,
    screenType: "narrow",
  },
  mutations: {
    /**
     * Load store from storeStorage
     * @param {*} state
     */
    initializeStore(state) {
      if (storeStorage.getItem("store")) {
        let saved = JSON.parse(storeStorage.getItem("store"));

        // Check the project version
        if (saved.version === version) {
          this.replaceState(Object.assign(state, saved));
        } else {
          state.version = version;
        }
      }
    },

    login(state) {
      state.login = true;
    },

    showPlaylist(state, value) {
      state.showPlaylist = value;
    },

    changeLyricsFormat(state) {
      if (state.lyricsFormat === "en") state.lyricsFormat = "en-ru";
      else state.lyricsFormat = "en";
    },

    uncoverReady(state, index) {
      Vue.set(state.uncoveredReady, index, true);
    },

    uncoverSeen(state, value) {
      Vue.set(state.uncoveredSeen, value, true);
    },

    changeTrack(state, value) {
      state.curtrack = value;
      if (value > state.maxtrack) state.maxtrack = value;
      state.progress = 0;
    },

    play(state, value) {
      state.playing = value;

      // Stop keepon after the playing track was sucessfully switched
      if (value) state.keepon = false;
    },

    setProgress(state, value) {
      if (value > 100) state.progress = 100;
      else if (value < 0) state.progress = 0;
      else state.progress = value;
    },

    checkScreen(state) {
      var ratio =
        document.documentElement.clientWidth /
        document.documentElement.clientHeight;

      if (ratio < 0.87) state.screenType = "narrow";
      else if (ratio > 1.47) state.screenType = "wide";
      else state.screenType = "square";
    },

    keepon(state, value) {
      state.keepon = value;
    },
  },
  actions: {},
  modules: {},

  strict: debug,
});

/**
 * Save state updates to storeStorage
 */
store.subscribe((mutation, state) => {
  let items = {
    version: state.version,
    login: state.login,
    curtrack: state.curtrack,
    maxtrack: state.maxtrack,
    lyricsFormat: state.lyricsFormat,
    progress: state.progress,
    uncoveredReady: state.uncoveredReady,
    uncoveredSeen: state.uncoveredSeen,
  };
  storeStorage.setItem("store", JSON.stringify(items));
});

export default store;
