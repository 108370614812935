<template>
  <div id="starsky2">
    <div class="background-container">
      <img src="./resources/moon.png" alt="" @mousedown="$emit('moon')" />
      <div class="stars"></div>
      <div class="twinkling"></div>
      <canvas id="shootingstars" />
      <div class="clouds"></div>
    </div>
  </div>
</template>

<script>
import { makeCanvasFullScreen } from "./resources/helper";
var background, bgCtx, width, height;

export default {
  mounted() {
    background = document.getElementById("shootingstars");
    bgCtx = background.getContext("2d");
    width = window.innerWidth;
    height = window.innerHeight;

    makeCanvasFullScreen(background);
    window.addEventListener("resize", () => {
      makeCanvasFullScreen(background);
    });

    var entities = [new ShootingStar(), new ShootingStar()];

    function animate() {
      bgCtx.globalCompositeOperation = "source-in";
      bgCtx.fillStyle = "#00000000";
      bgCtx.fillRect(0, 0, width, height);

      bgCtx.globalCompositeOperation = "source-over";
      bgCtx.fillStyle = "#ffffff";
      bgCtx.strokeStyle = "#ffffff";

      var entLen = entities.length;

      while (entLen--) {
        entities[entLen].update();
      }
      requestAnimationFrame(animate);
    }
    animate();
  },
};

function ShootingStar() {
  this.reset();
}

ShootingStar.prototype.reset = function() {
  this.x = Math.random() * width;
  this.y = (Math.random() * height) / 2;
  this.len = Math.random() * 50 + 10;
  this.speed = Math.random() * 10 + 12;
  this.size = Math.random() * 0.5 + 0.3;
  if (window.innerWidth >= 2000) this.size *= 3;
  // this is used so the shooting stars arent constant
  this.waitTime = new Date().getTime() + Math.random() * 10000 + 5000;
  this.active = false;
};

ShootingStar.prototype.update = function() {
  if (this.active) {
    this.x -= this.speed;
    this.y += this.speed;
    if (this.x < 0 || this.y >= height) {
      this.reset();
    } else {
      bgCtx.lineWidth = this.size;
      bgCtx.beginPath();
      bgCtx.moveTo(this.x, this.y);
      bgCtx.lineTo(this.x + this.len, this.y - this.len);
      bgCtx.stroke();
    }
  } else {
    if (this.waitTime < new Date().getTime()) {
      this.active = true;
    }
  }
};
</script>

<style></style>

<style scoped>
@keyframes move-background {
  from {
    transform: translate3d(0px, 0px, 0px);
  }
  to {
    transform: translate3d(40%, 0px, 0px);
  }
}

.background-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.stars {
  background: black url("./resources/stars.png") repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: -1;
}

.twinkling {
  width: 10000px;
  height: 100%;
  background: transparent url("./resources/twinkling.png") repeat;
  background-size: 1000px 1000px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  animation: move-background 70s linear infinite;
}

#shootingstars {
  z-index: 3;
}

.clouds {
  width: 10000px;
  height: 100%;
  background: transparent url("./resources/clouds.png") repeat;
  background-size: 1000px 1000px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.8;
  z-index: 4;
  animation: move-background 150s linear infinite;
}

@media (min-width: 2000px) {
  .clouds {
    background-size: 3000px 3000px;
  }
}

img {
  width: 35vh;
  position: absolute;
  z-index: 3;
  right: 4vw;
  filter: brightness(0.3);
  user-select: none;
  pointer-events: none;
}

@media (min-width: 1200px) {
  img {
    width: 50vh;
  }
}
</style>
